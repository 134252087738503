import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSeekCount } from "../../api/session";

const HideAndSeek = ({ id }) => {
  const dispath = useDispatch();
  const [hasFound, setHasFound] = useState(false);

  useEffect(() => {
    setHasFound(false);
    if (localStorage.getItem(id) === "found") {
      setHasFound(true);
    }
  }, [id, dispath]);

  function removeFadeOut(el, speed) {
    dispath(setSeekCount());
    localStorage.setItem(id, "found");
    var seconds = speed / 1000;
    el.style.transition = "opacity " + seconds + "s ease";
    el.style.opacity = 0;
    setTimeout(function () {
      setHasFound(true);
    }, speed);
  }

  //   removeFadeOut(document.getElementById("test"), 2000);
  console.log("hasFound ", hasFound);

  return (
    <>
      {!hasFound && (
        <div
          id="hide"
          style={{
            cursor: "pointer",
          }}
        >
          <img
            src="https://gfc.target.com/stencil-stage/nonprod/1732626378200_ufoundme.png"
            alt="bullssye"
            width="100px"
            onClick={() => removeFadeOut(document.getElementById("hide"), 1000)}
          />
        </div>
      )}
    </>
  );
};

export default HideAndSeek;
