import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
// import { IconButton } from '@mui/material';
// import LinkIcon from '@mui/icons-material/Link';
// import copy from 'copy-to-clipboard';
// import XIcon from '@mui/icons-material/X';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    // height: '450px',
    // width: '750px',
  },
  videoContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    border: "1px solid rgba(0,0,0,.2)",
    boxShadow: "24px ",
    borderRadius: ".3rem",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      //   height: '50%',
      width: "50%",
    },
  },
  videoPlayer: {
    padding: "1% 5% 5% 5%",
    width: "90%",
    height: "100%",
    // [theme.breakpoints.down('md')]: {
    //     padding: '0 7% 7% 7%',
    // },
  },
  closeIcon: {
    background: `url(https://gfc.target.com/elevatewebsiteproduction/cross.svg) no-repeat`,
    color: "#333333 !important",
    cursor: "pointer",
    width: "20px",
    height: "20px",
    border: "none",
    [theme.breakpoints.down("md")]: {
      width: "13px",
    },
  },
  visuallyHidden: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
  },
  closeIconContainer: {
    textAlign: "right",
    padding: "2% 2% 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "3% 3% 0 0",
    },
  },
  copyLink: {
    textAlign: "center",
    "& button": {
      // paddingTop: '7px',
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
      "&:focus": {
        outline: "1px solid #d3d3d3",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      paddingBottom: "6px",
    },
  },
  shareIcons: {
    fontSize: "20px !important",
    // '& :hover': {
    //   color: '#cc0000',
    // }
  },
  copyMsg: {
    // position: 'absolute',
    // top: '31px',
    fontSize: "13px",
    fontWeight: "bold",
    color: "#cc0000",
    left: "79px",
  },
}));

const VideoPlayer = ({ open, handleClose, videoSrc, poster, videoTitle, captions }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={open}
        role="dialog"
        onClose={handleClose}
        aria-label="video player dialog"
        className={classes.modalContainer}
      >
        <Box className={classes.videoContainer}>
          <h2 className={classes.visuallyHidden}>video player</h2>
          <div className={classes.closeIconContainer}>
            <button className={classes.closeIcon} onClick={handleClose} aria-label="close"></button>
          </div>
          <video
            playsInline
            autoPlay={true}
            controls
            className={classes.videoPlayer}
            controlsList="nodownload"
            crossOrigin="anonymous"
            poster={
              poster ? poster : "https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png"
            }
          >
            <source src={videoSrc} type="video/mp4" />
            <track src={captions} kind="subtitles" srcLang="en" label="English" />
          </video>
        </Box>
      </Modal>
    </div>
  );
};
export default VideoPlayer;
