import { Typography } from "@mui/material";
import RenderGallery from "../../Utils/RenderGallery";
import { courtyardPhotos, galleryPhotos, galleryVideos } from "./CourtyardPhotosData";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import RenderVideos from "./RenderVideos";
import HideAndSeek from "../../HideAndSeek/HideAndSeek";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourtyardPhotos = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "80%", margin: "0 auto" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
            sx={{ margin: "30px auto" }}
          >
            <Tab
              label="Courtyard of Joy"
              {...a11yProps(0)}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                border: "none",
                background: "#ffffff",
                padding: 0,
                alignItems: "flex-start !important",
                "&.Mui-selected": {
                  color: "#cc0000 !important",
                },
              }}
            />
            <div
              style={{
                // height: '20px',
                borderLeft: "1.5px solid",
                margin: "10px",
              }}
            ></div>
            <Tab
              label="TIIAM 2024 Images"
              {...a11yProps(1)}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                border: "none",
                padding: 0,
                // background: '#ffffff',
                alignItems: "flex-start !important",
                "&.Mui-selected": {
                  color: "#cc0000 !important",
                  backgroundColor: "transparent",
                },
              }}
            />
            <div
              style={{
                // height: '20px',
                borderLeft: "1.5px solid",
                margin: "10px",
              }}
            ></div>
            <Tab
              label="Videos"
              {...a11yProps(2)}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                border: "none",
                padding: 0,
                // background: '#ffffff',
                alignItems: "flex-start !important",
                "&.Mui-selected": {
                  color: "#cc0000 !important",
                  backgroundColor: "transparent",
                },
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RenderGallery images={courtyardPhotos} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RenderGallery images={galleryPhotos} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <RenderVideos galleryVideos={galleryVideos} />
        </CustomTabPanel>
      </Box>
      <HideAndSeek id="in_gallery" />
    </>
  );
};

export default CourtyardPhotos;
