import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import styles from "./Agenda.module.css";
import { CardMedia, Grid, Paper } from "@mui/material";
import WhatsInStore from "../WhatsInStore/WhatsInStore";
// import HideAndSeek from "../HideAndSeek/HideAndSeek";
const AgendaDay2 = () => {
  const RenderAgendaCard = ({
    tag,
    title,
    speaker,
    company,
    link,
    capacity,
    description,
    image,
    isRow = false,
    zoomLink = "",
  }) => {
    return (
      <>
        <Card
          elevation={0}
          sx={{
            minWidth: 275,
            textAlign: "left",
            backgroundColor: "transparent",
            display: !isRow && "flex",
            padding: "20px 20px 0 20px",
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div" sx={{ marginBottom: "15px", fontWeight: "400", color: "#808080" }}>
              {tag}
              {tag && <hr className={[styles["agenda-title-hr"]]} />}
            </Typography>
            <Typography variant="h6" component="div" sx={{ marginBottom: "15px", fontWeight: "400", color: "#CC0000" }}>
              {title}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "500", lineHeight: "1", marginBottom: "5px", fontSize: "18px" }}>
              {speaker}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: "300", lineHeight: "1.2", marginBottom: "15px", fontSize: "16px" }}
            >
              {company}
            </Typography>
            {image && isRow && (
              <CardMedia
                component="img"
                sx={{ width: "100%", objectFit: "contain", paddingBottom: "10px" }}
                image={`https://gfc.target.com/tiiam/tiiam24/${image}`}
                alt={speaker}
              />
            )}
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              {description}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              {zoomLink && (
                <a href={zoomLink} target="_blank" rel="noreferrer" style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Zoom link
                </a>
              )}
            </Typography>
          </CardContent>

          {image && !isRow && (
            <CardMedia
              component="img"
              sx={{ width: 150, objectFit: "contain" }}
              image={`https://gfc.target.com/tiiam/tiiam24/${image}`}
              alt="Live from space album cover"
            />
          )}

          {/* <CardMedia
            component='img'
            sx={{ width: 150, objectFit: 'contain' }}
            image={`https://gfc.target.com/tiiam/tiiam24/shwetambari.png`}
            alt='Live from space album cover'
          /> */}
        </Card>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 36px 10px 36px" }}>
          {capacity && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CardMedia
                component="img"
                sx={{ width: 50, objectFit: "contain" }}
                image={`https://gfc.target.com/tiiam/tiiam24/seats.png`}
                alt="Live from space album cover"
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "300", lineHeight: "1.2", fontSize: "14px", marginLeft: "5px" }}
              >
                {capacity === "TBD" ? `${capacity}` : `${capacity} max`}
              </Typography>
            </div>
          )}
          {link && (
            <div style={{ display: "flex", alignItems: "self-end" }}>
              <CardMedia
                component="img"
                sx={{ width: 23, objectFit: "contain" }}
                image={`https://gfc.target.com/tiiam/tiiam24/web_icon.png`}
                alt={speaker}
              />
              <Typography variant="h6" sx={{ fontWeight: "300", lineHeight: "1.2" }}>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "300", fontSize: "14px", marginLeft: "5px" }}
                >
                  Know More
                </a>
              </Typography>
            </div>
          )}
        </div>
      </>
    );
  };
  const C2Agenda = () => {
    return (
      <Grid item md={12}>
        <table className={styles["agenda-table"]}>
          <tr>
            <th></th>
            <th className={styles["agenda-location1"]}>
              <Typography variant="body2">Cafeteria - 8th Floor</Typography>
            </th>
            <th className={styles["agenda-location2"]}>
              {" "}
              <Typography variant="body2">Training Room - 7th Floor</Typography>
            </th>
            <th className={styles["agenda-location1"]}>
              <Typography variant="body2">Target Hall - Ground Floor</Typography>
            </th>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                10:00 -<br /> 10:30 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Wellness"}
                title={"Get Movin, Get Groovin"}
                speaker={"Shwetambari Shetty"}
                company={"Fitness Expert, Cure.Fit"}
                link={"https://www.cult.fit/"}
                description={
                  "An advocate for a holistic approach to fitness, Shwetambari will share simple tips to incorporate holistic fitness into one’s daily routines."
                }
                image={"shwetambari.png"}
                capacity={"300"}
              />
            </td>
            <td
              rowSpan={3}
              style={{
                backgroundColor: "#FFF8F8",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_01.png')",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Drum Up Some Fun"}
                speaker={"Roberto Narain"}
                company={"Drum Circle Facilitators"}
                description={
                  "Learn the basic skills of drumming and discover how you can come together as a team to discover your potential and create music together. "
                }
                link={"https://www.drumjam.in/home.html"}
                capacity={80}
                image={"Roberto-Narain.png"}
              />
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Tell a Mobile Tale"}
                speaker={"Rajiv Mathew Cherian"}
                company={"Professional Photographer"}
                description={
                  "Learn tips & tricks for mobile storytelling that will help you take the kind of Insta shots to make you a social superstar!  "
                }
                link={"https://www.instagram.com/malluwithacamera/"}
                capacity={80}
                image={"Rajiv-Mathew-Cherian.png"}
              />
            </td>
          </tr>
          <tr>
            <td colspan="2" className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                10:40 -<br /> 11:00 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                title={"The Magic of Abilities"}
                tag={"Inspiration"}
                speaker={"Alina Alam"}
                company={"CEO, Mitti Café"}
                link={"https://www.linkedin.com/in/alinamitticafe/"}
                capacity={500}
                description={
                  "Alina will share her journey of starting Mitti Café to give dignity and livelihoods to persons with disabilities and lessons in it for all of us."
                }
                image={"alina.png"}
                zoomLink={"https://target.zoom.us/j/94846604898?pwd=WjdTdXRSeEYyM0k1OXhFRVNrSkhWUT09"}
              />
              {/* <RenderAgendaCard
                title={''}
                speaker={'Trinetra Haldar Gummaraju,'}
                company={'Doctor, Actor, Content Creator'}
                description={' Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy'}
              /> */}
            </td>

            <td
              rowSpan={3}
              style={{
                backgroundColor: "#F2F2F2",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_02.png')",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            >
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Human Library"}
                speaker={""}
                company={""}
                description={
                  'Watch our leaders turn into "open books" with inspirational stories. They will bring their vulnerable selves, share unique life stories, and exchange diverse perspectives with team members.'
                }
                capacity={80}
              />
              {/* <HideAndSeek id="in_inspiration" /> */}
            </td>
          </tr>
          <tr>
            <td colspan="2" className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                11:10 -<br /> 11:30 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                title={"From Brew Waste to Great Taste"}
                tag={"Inspiration"}
                speaker={"Elizabeth Yorke"}
                company={"Founder, Saving Grains"}
                description={
                  "Elizabeth will share her journey of upcycling brewer spent grain & promoting food sustainability, and tips on reducing food waste at home."
                }
                link={"https://www.linkedin.com/in/elizabeth-yorke-59605934/"}
                capacity={500}
                image={"Elizabeth-Yorke.png"}
                zoomLink={"https://target.zoom.us/j/94846604898?pwd=WjdTdXRSeEYyM0k1OXhFRVNrSkhWUT09"}
              />
            </td>

            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Unlocking the Language of Signs Gestures "}
                speaker={"Indhudhara, Krishna and Shashidhar"}
                company={"GiftAbled"}
                description={
                  "Learn a new language - Indian sign language (ISL), understand the world of the deaf in their own language, and help change lives for the better."
                }
                link={"https://giftabled.org/"}
                capacity={80}
                image={"GiftAbled.png"}
                isRow={true}
              />
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" sx={{ textAlign: "left", fontSize: "20px" }}>
                12:00 -<br /> 1:30 pm
              </Typography>
            </td>
            <td colspan={3} className={styles["agenda-break"]}>
              <Typography variant="body2" sx={{ textAlign: "center !important", fontSize: "20px" }}>
                Lunch Break - Packed lunch in respective floor pantries
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                02:00 -<br /> 02:20 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Playing to Win"}
                speaker={"Deepthi Bopaiah"}
                company={"CEO, GoSports"}
                description={
                  "Discover what it takes to build a winning mindset with Deepthi Bopaiah who will draw from her experience of training Indian athletes to excel and go for gold."
                }
                link={"https://www.linkedin.com/in/deepthi-bopaiah-810444a/"}
                capacity={500}
                image={"deepthi.png"}
                zoomLink={"https://target.zoom.us/j/93693819956?pwd=MUYrc280S291STcxQ0gxQ0VGT0lqZz09"}
              />
            </td>

            <td
              rowSpan={3}
              style={{
                backgroundColor: "#F2F2F2",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_03.png')",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Doodle Drama"}
                speaker={"Mounica Tata"}
                company={"Founder, Doodleodrama"}
                description={
                  "Get ready for some doodle fun with Mounica Tata who will bring stories to life through Doodleodrama and help you unlock your imagination."
                }
                link={"https://www.linkedin.com/in/mounica-tata/"}
                capacity={80}
                image={"monica.png"}
              />
            </td>
            <td
              rowSpan={3}
              style={{
                backgroundColor: "#FFF8F8",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_04.png')",
                backgroundPosition: "left",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Living Zero Waste "}
                speaker={"Sahar Mansoor"}
                company={"Founder & CEO, Bare Necessities"}
                description={
                  "Learn how you can practically get started with tackling your waste footprint, and simple steps to incorporate a zero-waste lifestyle in your life. "
                }
                link={"https://barenecessities.in/"}
                capacity={80}
                image={"sahar.png"}
              />
            </td>
          </tr>
          <tr>{/* <td colSpan={2} className={styles['agenda-break']}></td> */}</tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                02:30 -<br /> 02:50 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                title={"Living Life Fragile"}
                tag={"Inspiration"}
                speaker={"Dhanya Ravi"}
                company={"Disability Evangelist"}
                description={
                  "Listen to the story of Dhanya who, born with brittle bone disease, has overcome several challenges and is a powerful advocate for the rare disease community."
                }
                link={"https://www.linkedin.com/in/dhanyaravi/"}
                capacity={500}
                image={"dhanya.png"}
                zoomLink={"https://target.zoom.us/j/93693819956?pwd=MUYrc280S291STcxQ0gxQ0VGT0lqZz09"}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                03:00 -<br /> 03:30 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Wellness"}
                title={"Hoops of Fun with Supriya"}
                speaker={"Supriya Srivastav"}
                company={"Founder, Hoop with Supriya"}
                description={
                  "Push boundaries, have fun, and move out of your comfort zone with hula hoops that will also up your fitness quotient."
                }
                link={"https://www.linkedin.com/in/supriya-srivastav-19790426/"}
                capacity={120}
                image={"supriya.png"}
              />
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={""}
                speaker={"Human Library"}
                company={""}
                description={
                  'Watch our leaders turn into "open books" with inspirational stories. They will bring their vulnerable selves, share unique life stories, and exchange diverse perspectives with team members.'
                }
                capacity={80}
              />
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Wealth from Waste"}
                speaker={"Vishwanath Mallabadi"}
                company={"Eco Artist"}
                description={
                  "This eco-champion will share how he transforms e-waste into stunning eco-art, combining creativity and technology, reducing the impact on landfills."
                }
                link={"https://www.saatchiart.com/"}
                capacity={80}
                image={"vishwanath.png"}
              />
            </td>
          </tr>
        </table>
      </Grid>
    );
  };
  const NXTAgenda = () => {
    return (
      <Grid item md={12}>
        <table className={styles["agenda-table"]}>
          <tr>
            <th></th>
            <th className={styles["agenda-location1"]}>
              <Typography variant="body2">Cafeteria - 9th Floor</Typography>
            </th>
            <th className={styles["agenda-location2"]}>
              {" "}
              <Typography variant="body2">Training Room - 9th Floor</Typography>
            </th>
            <th className={styles["agenda-location1"]}>
              <Typography variant="body2">Target Hall - 3rd Floor</Typography>
            </th>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                10:00 -<br /> 10:30 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Wellness"}
                title={"Hoops of Fun with Supriya"}
                speaker={"Supriya Srivastav"}
                company={"Founder, Hoop with Supriya"}
                description={
                  "Push boundaries, have fun, and move out of your comfort zone with hula hoops that will also up your fitness quotient."
                }
                link={"https://www.linkedin.com/in/supriya-srivastav-19790426/"}
                capacity={100}
                image={"supriya.png"}
              />
            </td>

            <td
              rowSpan={3}
              style={{
                backgroundColor: "#F2F2F2",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_03.png')",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Doodle Drama"}
                speaker={"Mounica Tata"}
                company={"Founder, Doodleodrama"}
                description={
                  "Get ready for some doodle fun with Mounica Tata who will bring stories to life through Doodleodrama and help you unlock your imagination."
                }
                link={"https://www.linkedin.com/in/mounica-tata/"}
                capacity={160}
                image={"monica.png"}
              />
            </td>
            <td
              rowSpan={3}
              style={{
                backgroundColor: "#FFF8F8",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_04.png')",
                backgroundPosition: "left",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Living Zero Waste "}
                speaker={"Sahar Mansoor"}
                company={"Founder & CEO, Bare Necessities"}
                description={
                  "Learn how you can practically get started with tackling your waste footprint, and simple steps to incorporate a zero-waste lifestyle in your life. "
                }
                link={"https://barenecessities.in/"}
                capacity={60}
                image={"sahar.png"}
              />
            </td>
          </tr>
          <tr>{/* <td colSpan={2} className={styles['agenda-break']}></td> */}</tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                10:40 -<br /> 11:00 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                title={"Living Life Fragile"}
                tag={"Inspiration"}
                speaker={"Dhanya Ravi"}
                company={"Disability Evangelist"}
                description={
                  "Listen to the story of Dhanya who, born with brittle bone disease, has overcome several challenges and is a powerful advocate for the rare disease community."
                }
                link={"https://www.linkedin.com/in/dhanyaravi/"}
                capacity={300}
                image={"dhanya.png"}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" className={styles["agenda-break-short"]}>
                10 mins break
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                11:10 -<br /> 11:30 am
              </Typography>
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Playing to Win"}
                speaker={"Deepthi Bopaiah"}
                company={"CEO, GoSports"}
                description={
                  "Discover what it takes to build a winning mindset with Deepthi Bopaiah who will draw from her experience of training Indian athletes to excel and go for gold."
                }
                link={"https://www.linkedin.com/in/deepthi-bopaiah-810444a/"}
                capacity={300}
                image={"deepthi.png"}
              />
            </td>
            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={""}
                speaker={"Human Library"}
                company={""}
                description={
                  'Watch our leaders turn into "open books" with inspirational stories. They will bring their vulnerable selves, share unique life stories, and exchange diverse perspectives with team members.'
                }
                capacity={160}
              />
              {/* <HideAndSeek id="in_library" /> */}
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Wealth from Waste"}
                speaker={"Vishwanath Mallabadi"}
                company={"Eco Artist"}
                description={
                  "This eco-champion will share how he transforms e-waste into stunning eco-art, combining creativity and technology, reducing the impact on landfills."
                }
                link={"https://www.saatchiart.com/"}
                capacity={60}
                image={"vishwanath.png"}
              />
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2" sx={{ textAlign: "left", fontSize: "20px" }}>
                12:00 -<br /> 1:30 pm
              </Typography>
            </td>
            <td colspan={3} className={styles["agenda-break"]}>
              <Typography variant="body2" sx={{ textAlign: "center !important", fontSize: "20px" }}>
                Lunch Break - Packed lunch in respective floor pantries
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                02:00 -<br /> 02:20 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                title={"From Brew Waste to Great Taste"}
                tag={"Inspiration"}
                speaker={"Elizabeth Yorke"}
                company={"Founder, Saving Grains"}
                description={
                  "Elizabeth will share her journey of upcycling brewer spent grain & promoting food sustainability, and tips on reducing food waste at home."
                }
                link={"https://www.linkedin.com/in/elizabeth-yorke-59605934/"}
                capacity={300}
                image={"Elizabeth-Yorke.png"}
              />
            </td>
            <td
              rowSpan={3}
              style={{
                backgroundColor: "#FFF8F8",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_01.png')",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Drum Up Some Fun"}
                speaker={"Roberto Narain"}
                company={"Drum Circle Facilitators"}
                description={
                  "Learn the basic skills of drumming and discover how you can come together as a team to discover your potential and create music together. "
                }
                link={"https://www.drumjam.in/home.html"}
                capacity={160}
                image={"Roberto-Narain.png"}
              />
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Tell a Mobile Tale"}
                speaker={"Rajiv Mathew Cherian"}
                company={"Professional Photographer"}
                description={
                  "Learn tips & tricks for mobile storytelling that will help you take the kind of Insta shots to make you a social superstar!  "
                }
                link={"https://www.instagram.com/malluwithacamera/"}
                capacity={60}
                image={"Rajiv-Mathew-Cherian.png"}
              />
              {/* <HideAndSeek id={"in_mobile"} /> */}
            </td>
          </tr>
          <tr>
            <td colspan="2" className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                02:30 -<br /> 02:50 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                title={"The Magic of Abilities"}
                tag={"Inspiration"}
                speaker={"Alina Alam"}
                company={"CEO, Mitti Café"}
                link={"https://www.linkedin.com/in/alinamitticafe/"}
                capacity={300}
                description={
                  "Alina will share her journey of starting Mitti Café to give dignity and livelihoods to persons with disabilities and lessons in it for all of us."
                }
                image={"alina.png"}
              />
              {/* <RenderAgendaCard
                title={''}
                speaker={'Trinetra Haldar Gummaraju,'}
                company={'Doctor, Actor, Content Creator'}
                description={' Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy'}
              /> */}
            </td>

            <td
              rowSpan={3}
              style={{
                backgroundColor: "#F2F2F2",
                verticalAlign: "baseline",
                backgroundImage: "url('https://gfc.target.com/tiiam/tiiam24/graphic_02.png')",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            >
              <RenderAgendaCard
                tag={"Inspiration"}
                title={"Human Library"}
                speaker={""}
                company={""}
                description={
                  'Watch our leaders turn into "open books" with inspirational stories. They will bring their vulnerable selves, share unique life stories, and exchange diverse perspectives with team members.'
                }
                capacity={60}
              />
            </td>
          </tr>
          <tr>
            <td colspan="2" className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
            <td className={styles["agenda-break"]}>
              <Typography variant="body2">10 mins break</Typography>
            </td>
          </tr>
          <tr>
            <td className={styles["agenda-time"]}>
              <Typography variant="body2">
                03:00 -<br /> 03:30 pm
              </Typography>
            </td>
            <td style={{ backgroundColor: "#F2F2F2" }}>
              <RenderAgendaCard
                tag={"Wellness"}
                title={"Get Movin, Get Groovin"}
                speaker={"Shwetambari Shetty"}
                company={"Fitness Expert, Cure.Fit"}
                link={"https://www.cult.fit/"}
                description={
                  "An advocate for a holistic approach to fitness, Shwetambari will share simple tips to incorporate holistic fitness into one’s daily routines."
                }
                image={"shwetambari.png"}
                capacity={"200"}
              />
            </td>

            <td style={{ backgroundColor: "#FFF8F8" }}>
              <RenderAgendaCard
                tag={"Workshop"}
                title={"Unlocking the Language of Signs Gestures "}
                speaker={"Indhudhara, Krishna and Shadhidhar"}
                company={"GiftAbled"}
                description={
                  "Learn a new language - Indian sign language (ISL), understand the world of the deaf in their own language, and help change lives for the better."
                }
                link={"https://giftabled.org/"}
                capacity={160}
                image={"GiftAbled.png"}
                isRow={true}
              />
            </td>
          </tr>
        </table>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid item md={12}>
        <Paper
          elevation={0}
          sx={{
            textAlign: "left",
            padding: "40px",
            backgroundImage: "url(https://gfc.target.com/tiiam/tiiam24/sub-banner_Agenda_17th.png)",
            height: "230px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundColor: "#faebea",
          }}
        >
          <Grid item md={6}>
            <Typography variant="h3" sx={{ marginBottom: "20px" }}>
              Engagement activities
            </Typography>
            <Typography variant="body">
              Each session can accommodate a limited number of participants and we would love for everyone to have an
              opportunity to participate. Choose a session to attend that most suits your interest so others have an
              opportunity to attend as well. You get access to all sessions on a first-come first-served basis. Bookmark
              this page for easy reference.
            </Typography>
          </Grid>
        </Paper>
        <div style={{ marginTop: "-20px" }}>
          <WhatsInStore isSubTab={true} tabNames={["C2", "NXT"]} tabNamePanels={[<C2Agenda />, <NXTAgenda />]} />
        </div>
      </Grid>
    </Grid>
  );
};

export default AgendaDay2;
