import merge from "lodash/merge";

// configurations shared between most or all environments can be stored in commonConfig
const commonConfig = {
  api: {
    host: "https://stage-api.target.com/",
    key: "229fe536e9d9e484d3a3e97d744e0446b685cf00",
  },
  authURL: "https://oauth.iam.perf.target.com/auth/oauth/v2/authorize",
  clientID: "tiiam2022final_npe_im",
};

const envConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .drone.yml
  dev: {
    api: {
      host: "https://stage-api.target.com/",
      key: "229fe536e9d9e484d3a3e97d744e0446b685cf00",
    },
    clientID: "tiiam2022final_npe_im",
  },

  prod: {
    clientID: "tiiam2022final_prod_im",
    authURL: "https://oauth.iam.target.com/auth/oauth/v2/authorize",
    api: {
      host: "https://api.target.com/",
      key: "a0598ca9e9f6158c77091c2bed1c39dea63d4799",
    },
  },
};
const appEnv = process.env.REACT_APP_ENV;
console.log("appEnv ", appEnv);
const config = envConfigs[appEnv];

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys
// if they share the same name.
const apiConfig = merge(commonConfig, config);

export default apiConfig;
