import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {
    fullName: "",
  },
  identityToken: "",
  seekCount: 0,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userInfo = action.payload ? action.payload.userInfo : initialState.userInfo;
      state.identityToken = action.payload.identityToken ? action.payload.identityToken : initialState.identityToken;
    },
    setSeekCount: (state, action) => {
      state.seekCount = state.seekCount + 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserSession, setSeekCount } = sessionSlice.actions;

export default sessionSlice.reducer;
