import GifInAR from "./GifInAR";
import { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

const ARScanner = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const event = queryParams.get("event");
  console.log("event type ", event);

  const [openModal, setModalOpen] = useState(true);
  const [showOk, setShowOk] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "22px 18px",
    outline: "none",
    borderRadius: "20px",
  };

  function renderSwitch(param) {
    switch (param) {
      case "birthday":
        return (
          <>
            <GifInAR
              imgTarget={"https://gfc.target.com/marketing/AR_2024/arinmail/birthday.mind"}
              gifSrc={"https://gfc.target.com/marketing/AR_2024/arinmail/birthday.gif"}
              setModalOpen={setModalOpen}
              setShowOk={setShowOk}
            />
          </>
        );
      case "anniversary":
        return (
          <GifInAR
            imgTarget={"https://gfc.target.com/marketing/AR_2024/arinmail/anniversary.mind"}
            gifSrc={"https://gfc.target.com/marketing/AR_2024/arinmail/anniversary.gif"}
            setModalOpen={setModalOpen}
            setShowOk={setShowOk}
          />
        );
      default:
        return <></>;
    }
  }

  function goFullscreen() {
    // const elem = document.documentElement;
    // if (elem.requestFullscreen) {
    //   elem.requestFullscreen();
    // } else if (elem.webkitRequestFullscreen) {
    //   elem.webkitRequestFullscreen();
    // }
    setModalOpen(false);
  }

  return (
    <>
      <Modal
        open={openModal}
        role="dialog"
        onClose={() => setModalOpen(false)}
        aria-label="video player dialog"
        sx={{
          "& .focus-visible": {
            outline: "none",
          },
        }}
      >
        <Box sx={style}>
          {/* <div
            style={{ position: "absolute", right: 10, top: "10px", cursor: "pointer" }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </div> */}

          <Typography id="modal-modal-title" variant="h6" sx={{ fontSize: "20px" }}>
            Keep scanning the QR code for the magic
          </Typography>
          {showOk && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button variant="outlined" sx={{ color: "#000", border: "1px solid #666" }} onClick={goFullscreen}>
                ok
              </Button>
            </div>
          )}
        </Box>
      </Modal>
      <>{renderSwitch(event)}</>
    </>
  );
};

export default ARScanner;
