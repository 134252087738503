import { Typography } from "@mui/material";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setSeekCount } from "../../api/session";
import ConfettiExplosion from "react-confetti-explosion";

const SeekTracker = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const seekIds = ["in_gallery", "in_faq", "in_outerspace"];
  const dispatch = useDispatch();

  useEffect(() => {
    seekIds.forEach((id) => {
      if (localStorage.getItem(id) === "found") {
        dispatch(setSeekCount());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const seekCount = useSelector((state) => state.session.seekCount);
  console.log("seekCount ", seekCount);
  return (
    <>
      <div
        style={{
          position: "fixed",
          right: "10px",
          marginTop: "20px",
          zIndex: 100,
          backgroundColor: "#fff",
          color: "#000",
          padding: "20px",
          top: "34px",
          borderBottom: "4px solid #000",
          borderRadius: "0 20px 20px 0",
        }}
      >
        {seekCount === seekIds.length && <ConfettiExplosion duration={5200} />}

        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          seek count {seekCount}/{seekIds.length}
        </Typography>
      </div>
    </>
  );
};

export default SeekTracker;
