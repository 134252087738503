import React, { useEffect } from "react";

const GifInAR = ({ imgTarget, gifSrc, setModalOpen, setShowOk }) => {
  console.log({ imgTarget, gifSrc });

  useEffect(() => {
    // Listen to targetFound and targetLost events from A-Frame
    const targetEntity = document.querySelector("[mindar-image-target]");
    const gifElement = document.getElementById("gif-overlay");
    targetEntity.addEventListener("targetFound", () => {
      gifElement.style.display = "block"; // Show the GIF
      setModalOpen(false);
      setShowOk(false);
    });
    targetEntity.addEventListener("targetLost", () => {
      gifElement.style.display = "none"; // Hide the GIF
      setModalOpen(true);
      setShowOk(false);
    });
  }, [setModalOpen, setShowOk]);
  return (
    <>
      <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
        {/* AR Scene */}
        <a-scene
          mindar-image={`imageTargetSrc: ${imgTarget}`}
          embedded
          color-space="sRGB"
          style={{ width: "100%", height: "100%" }}
        >
          <a-camera position="0 0 0" look-controls="false"></a-camera>
          {/* Target Entity */}
          <a-entity mindar-image-target="targetIndex: 0">
            {/* <a-plane position="0 0 0" rotation="0 0 0" width="1" height="1" color="white" opacity="0.5"></a-plane> */}
          </a-entity>
        </a-scene>
        {/* GIF Overlay */}
        <img
          id="gif-overlay"
          src={gifSrc}
          alt="AR GIF"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            display: "none", // Initially hidden
          }}
        />
      </div>
    </>
  );
};
export default GifInAR;
