import { Badge, Box, Button } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState } from "react";

const Emojis = () => {
  const [likesCnt, SetLikesCnt] = useState(10);
  const [hasLiked, setHasLiked] = useState(false);

  //   async function handleLike() {
  //     const likedUser = {
  //       videoId,
  //       hasLiked: true,
  //     };
  //     localStorage.setItem("hasLiked", JSON.stringify(likedUser));
  //     setHasLiked(true);
  //     try {
  //       axios
  //         .post("http://localhost:8080/annual_meeting_contents/v1/arvideolikes", {
  //           video_id: videoId,
  //         })
  //         .then((res, err) => {
  //           if (err) console.log("like post err", err);
  //           SetLikesCnt(res.data.likes.length);
  //         });
  //     } catch (error) {
  //       console.log("like post Error ", error);
  //     }
  //   }
  function handleLike() {
    // localStorage.setItem("hasLiked", true);
    SetLikesCnt(likesCnt + 1);
    setHasLiked(true);
  }

  return (
    <>
      <Box sx={{ mb: "45px" }}>
        <Button onClick={handleLike} disabled={hasLiked}>
          {likesCnt > 0 && hasLiked ? (
            <FavoriteIcon sx={{ color: "#cc0000" }} />
          ) : (
            <FavoriteBorderIcon sx={{ color: "#cc0000" }} />
          )}
        </Button>
        <Badge
          showZero={true}
          badgeContent={likesCnt}
          sx={{ color: "#cc0000", "& .MuiBadge-badge": { fontSize: "20px" } }}
        ></Badge>
      </Box>
    </>
  );
};

export default Emojis;
